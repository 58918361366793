@use '../../styles/global.scss' as *;
@use '../../styles/mixins.scss' as *;
@use '../../styles/variables.scss' as *;


.contact {
    background-color: rgb(5, 13, 9, .9);

    &__header {
        height: 84.5vh;
        background-image: linear-gradient(rgba(230, 205, 147, 0.05), rgba(19, 67, 63, 0.1)), url('../../assets/images/lantern1.jpg');
        background-size: cover;
        background-position: center bottom 95%;
        display: flex;
        align-items: center;
        border-bottom-left-radius: 22rem;
        justify-content: flex-end;

        @include headerHeight;

        &-wrapper {
            margin: 46rem 18rem 0;
        }

        &-title {
            @include redTitle;
        }

        &-text {
            color: white;
            font-size: 3rem;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
            text-transform: capitalize;
        }
    }

    &__form {
        margin-top: 10rem;
        display: flex;
        justify-content: center;

        &-wrapper {
            background-color: rgba(247, 229, 200, 0.8);
            width: 78vw;
        }

        &-title {
            text-align: center;
            @include blueSub;
            font-size: 7rem;
            margin: 0 0 5rem 0;
        }

        &-form {
            margin: 10rem;
            display: flex;
            justify-content: space-between;
        }

        &-left {
            display: flex;
            flex-direction: column;
            width: 55%;
        }

        &-right {
            background-image: linear-gradient(rgba(230, 205, 147, .1),
                    rgba(230, 205, 147, 0.1)),
                url('../../assets/images/xianwall.JPG');
            width: 35%;
            background-size: cover;
            background-position: center left 50%;
            border-radius: 5px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3)
        }

        &-block {
            display: flex;
            justify-content: space-between;
        }

        &-message {
            border-radius: 5px;
            border: none;
            background-color: white;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            padding: 2rem 1.5rem;
            font-size: 1.8rem;
            font-family: Roboto, 'Times New Roman', Times, serif;
            width: 100%;
            height: 30rem;
            line-height: 2rem;
            margin-bottom: 2rem;

            &::placeholder {
                color: rgba(41, 71, 87, 0.8);
            }
        }

        &-btn {
            @include blueBtn;
            background-color: $textGreyblue;
            margin-left: 0;

            &:hover {
                border: 3.5px solid $btnBlue;
            }

            &-modal {
                background-color: white;
                color: rgb(86, 150, 187);
                align-self: center;
                margin: 3rem 0 0;
            }
        }

        &-subtitle {
            margin-bottom: 2rem;
            font-size: 1.8rem;
            color: $textGreyblue;
            font-style: italic;

        }

        &-radios {
            display: flex;
        }

        &-label {
            width: 33%;
            font-size: 1.8rem;
            color: rgba(41, 71, 87, 0.8);
            padding: .5rem;
        }

        &-phone {
            display: flex;
            flex-direction: column;
            width: 47%;
        }

        &-phoneinput {

            & input {
                border: none;
                font-size: 1.8rem;

                &:focus {
                    border: none !important;
                    outline: none !important;
                }
            }

            &.PhoneInput {
                background-color: white;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                height: 5rem;
                margin-bottom: 2rem;
                border-radius: 5px;
                background-color: white;
                padding: 1.5rem;
            }

        }

        &-error {
            border: 1.5px solid rgb(174, 174, 174);
            height: 5rem;
            border-radius: 5px;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);

            &-msg {
                font-size: 1.4rem;
                margin-left: 1rem;
            }

            &-icon {
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 4px;
            }

            &-hidden {
                display: none;
            }
        }

        &-result {
            background-color: rgba(255, 255, 255, 0.65);
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            &-modal {
                width: 60rem;
                height: 50rem;
                background: linear-gradient(to right, #f6beda, rgb(86, 150, 187));
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                z-index: 103;
            }

            &-icon {
                width: 15rem;
                height: 10rem;

                &-sm {
                    width: 12rem;
                }
            }

            &-msg {
                margin: 4rem 0 2.5rem 0;
                font-family: Voltaire, Yesvea, 'Times New Roman', Times, serif;
                font-size: 4rem;
                color: white;
                text-shadow: 1px 1px 2px rgb(18, 17, 17, .5);
            }

            &-sub {
                width: 70%;
                font-size: 2.2rem;
                text-align: center;
                color: white;
                font-weight: bold;
                text-shadow: 1px 1px 2px rgb(18, 17, 17, .5);
            }

            &-close {
                display: none;
            }
        }


    }

}


// media query 

@media (max-width: 425px) {
    .contact {

        &__header {

            &-title {
                font-size: 4rem;
                text-align: right;
                margin-bottom: .5rem;
            }

            &-wrapper {
                margin: 18rem 2.8rem 0;
            }

            &-text {
                font-size: 1.8rem;
                width: 103%;
            }

        }

        &__form {
            &-result {

                &-modal {
                    width: 100vw;
                    height: 100vh;
                    border-radius: 0;
                }

                // &-icon {
                //     &-close {
                //         top: 10rem;
                //         right: 3.5rem;
                //     }
                // }

                &-msg {
                    font-size: 4.5rem;
                    margin: 4rem;
                }
            }
        }
    }
}

@media (min-width: 426px) and (max-width: $breakpoint-md) {
    .contact {

        &__form {
            &-result {

                &-modal {
                    width: 41rem;
                }
            }
        }
    }
}

@media (min-width: 426px) and (max-width: $breakpoint-xs) {
    .contact {
        &__header {

            &-title {
                font-size: 5rem;
            }

            &-wrapper {
                margin: 18rem 5rem 0;
            }

            &-text {
                font-size: 2rem;
            }

        }
    }

}

@media (max-width: $breakpoint-sm) {
    .contact {
        &__header {

            &-wrapper {
                position: relative;
            }

            &-text {
                text-align: right;
                position: absolute;
                right: 0;
            }

        }

        &__form {
            &-left {
                width: 100%;
            }

            &-right {
                display: none;
            }


            &-block {
                flex-direction: column;

            }

            &-phone {
                width: 100%;
            }

            &-btn {
                width: 12rem;
                height: 4rem;
                margin-top: 1rem;

                &-modal {
                    margin: 3rem 0 0;
                }
            }

            &-subtitle {
                font-size: 1.7rem;
            }

            &-label {
                font-size: 1.6rem;
            }
        }

    }
}


@media (min-width: 320px) and (max-width: $breakpoint-xs) {
    .contact {

        &__form {
            margin: 5rem;

            &-title {
                font-size: 3.5rem;
                margin-bottom: 2rem;
            }

            &-form {
                margin: 3rem;
            }

        }
    }

}

@media (min-width: 577px) and (max-width: $breakpoint-sm) {
    .contact {
        &__header {

            &-title {
                font-size: 6rem;
            }

            &-wrapper {
                margin: 21rem 5rem 0;
            }

            &-text {
                font-size: 2.5rem;
            }

        }

        &__form {
            margin-top: 7rem;

            &-title {
                font-size: 6rem;
            }

            &-form {
                margin: 5rem;
            }
        }
    }
}

@media (min-width: 769px) and (max-width: $breakpoint-md) {
    .contact {

        &__header {

            &-title {
                font-size: 7rem;
            }

            &-wrapper {
                margin: 27rem 5rem 0;
            }

            &-text {
                font-size: 2.6rem;
            }

        }

        &__form {
            margin: 7rem;

            &-title {
                font-size: 5rem;
                margin-bottom: 2rem;
            }

            &-form {
                margin: 4rem;
            }

            &-block {
                flex-direction: column;
            }

            &-phone {
                width: 100%;
            }

            &-right {
                background-position: center left 70%;
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1140px) {
    .contact {
        &__form {

            &-title {
                font-size: 6.5rem;
                margin-bottom: 2rem;
            }

            &-form {
                margin: 5rem 5rem 5rem;
            }

            &-right {
                background-position: center left 38%;
            }
        }
    }
}

@media (min-width: 993px) and (max-width: $breakpoint-lg) {
    .contact {

        &__header {

            &-title {
                font-size: 9rem;
            }

            &-wrapper {
                margin: 38rem 10rem 0;
            }

            &-text {
                font-size: 2.8rem;
            }

        }
    }
}

@media (min-width: 1201px) and (max-width:1365px) {
    .contact {
        &-error {
            width: 23rem;
        }
    }
}