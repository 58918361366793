$bigRed: rgb(202, 2, 2);
$shadowRed: rgba(85, 0, 18);
$btnBlue: rgb(86, 150, 187);
$textGreyblue: rgb(41, 71, 87);
$bgBlue: rgb(68, 119, 144);
$darkText: rgb(29, 50, 60);


// responsive sizes
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1280px;