@use '../../styles/global.scss' as *;
@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;


.trip {
    background-color: rgba(89, 125, 133, 0.8);


    &__header {
        height: 84.5vh;
        background-image: linear-gradient(rgba(19, 67, 63, 0.2), rgba(19, 67, 63, 0.2)), url('../../assets/images/palace.jpg');
        background-size: cover;
        background-position: center top 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 22rem;

        @include headerHeight;

        &-wrapper {
            width: 75%;
            margin-top: 30rem;
        }

        &-date,
        &-title,
        &-text {
            color: white;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
        }

        &-date {
            font-family: Mitr, 'Times New Roman', Times, serif;
            text-transform: uppercase;
            font-size: 2.8rem;
            font-weight: 300;
        }

        &-title {
            @include redTitle;
            font-family: Voltaire, 'Times New Roman', Times, serif;
        }

        &-text {
            font-size: 5rem;
            font-family: Voltaire, 'Times New Roman', Times, serif;
            margin-top: 1.5rem;
        }
    }


    &__main {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-sec {
            width: 78vw;
            margin-top: 9rem;
            display: flex;
            justify-content: space-between;

            &-left,
            &-right {
                width: 45%;
            }

            &-title {
                @include blueSub;
                font-size: 5rem;
                margin-bottom: 3rem;


            }

            &-text {
                width: 90%;
                font-size: 2.2rem;
                color: rgb(16, 27, 31);
                margin: 2rem 0;
                line-height: 2.8rem;
                text-indent: 2rem;
            }

            &-right {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
            }

            &-img1 {
                width: 48%;
                height: 35rem;
                border-radius: 5px;
                background-image: linear-gradient(rgba(19, 67, 63, 0.25), rgba(19, 67, 63, 0.25)), url('../../assets/images/cooking1.JPG');
                background-size: cover;
                background-position: left;
                margin-top: 6rem;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2)
            }

            &-img2 {
                width: 48%;
                height: 41rem;
                border-radius: 5px;
                background-image: linear-gradient(rgba(19, 67, 63, 0.25), rgba(19, 67, 63, 0.25)), url('../../assets/images/cooking3.jpg');
                background-size: cover;
                background-position: center bottom;
                box-shadow: 1px 1px 2px rgba(89, 125, 133, 1.5);
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2)
            }

            &-img3 {
                display: none;
            }

            &-img {
                width: 45%;
                align-self: center;
                background-image: linear-gradient(rgba(19, 67, 63, 0.2), rgba(19, 67, 63, 0.2)), url('../../assets/images/tobaggan.JPG');
                background-position: center right;
                border-radius: 5px;
                height: 33rem;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2)
            }
        }
    }


    &__detail {
        margin-top: 10rem;
        display: flex;


        &-title {
            position: relative;
            @include blueSub;
            font-size: 5rem;
            text-align: center;
            margin-bottom: 3rem;
        }

        &-title2 {
            display: none;
        }

        &-subtitle {
            padding-bottom: .5rem;
            font-family: Yeseva, 'Times New Roman', Times, serif;
        }

        &-wrapper {
            width: 78vw;
            display: flex;
            justify-content: space-between;
        }

        &-left {
            width: 60%;
        }

        &-right {
            width: 30%;
            background-image: linear-gradient(rgba(19, 67, 63, 0.25), rgba(19, 67, 63, 0.25)), url('../../assets/images/pagoda.jpg');
            background-size: cover;
            box-shadow: 1px 1px 5px rgba(89, 125, 133, 0.8);
        }

        &-price {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-left: 3rem;

            &-block {
                background-color: rgb(171, 205, 218);
                width: 47%;
                padding-bottom: 3rem;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }

            &-info {
                padding: 3rem;
            }

        }

        &-block {
            background-color: rgb(171, 205, 218);
            width: 100%;
            margin: 3rem;
            display: flex;
            align-items: center;
            padding: 1rem 0;
            gap: 4rem;

            &-btns {
                background-color: transparent;
            }
        }

        &-icon {
            display: flex;
            align-items: center;

            &-img {
                width: 5rem;
                height: 5rem;
                margin-left: 3rem;
            }
        }

        &-info {
            color: $darkText;
            font-size: 3.5rem;
            font-family: Voltaire, 'Times New Roman', Times, serif;

            &-list {
                margin-left: 8rem;
            }
        }

        &-item {
            font-weight: bold;
        }


        &-btn {
            @include blueBtn;
            height: 6rem;
            width: 18rem;
            margin: 0;
            font-size: 130%;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

            &:hover {
                border: 3.7px solid white;
            }
        }

        &__link {
            text-decoration: none;
        }
    }
}

.itinerary {
    width: 100vw;
    margin-top: 10rem;
    padding-bottom: 10rem;
    display: flex;
    flex-direction: column;

    &__wrapper {
        width: 75vw;
        position: relative;
        background-color: rgb(0, 54, 49);
        display: flex;
        margin-bottom: 4rem;

        &-left {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }

        &-right {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            align-self: flex-end;
        }
    }

    &__caption {
        @include blueSub;
        text-align: center;
        font-size: 7rem;
        margin: 5rem 0 2rem;

        &-underline {
            height: 1rem;
            width: 60%;
            border-top: 8px solid rgb(0, 54, 49);
            margin-bottom: 5rem;
            align-self: flex-end;
        }
    }

    &__block {
        width: 50%;
        margin-left: 12%;
        padding: 4rem 5rem 0 0;
        color: rgb(171, 205, 218);

        &-right {
            margin-left: 8%;
        }
    }

    &__subtitle {
        font-family: Roboto, 'Times New Roman', Times, serif;
        font-size: 1.8rem;
        font-style: italic;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
    }

    &__title {
        font-family: Voltaire, 'Times New Roman', Times, serif;
        font-size: 3rem;
        margin-bottom: 1.5rem;
    }

    &__list {
        margin-left: 2rem;
    }

    &__item {
        font-size: 2rem;
        padding: 0 0 1rem 1rem;

        &:last-child {
            padding-bottom: 3rem;
        }

    }

    &__img {
        width: 38%;
        border-radius: 15px;
        box-shadow: 1px 1px 3px rgb(0, 54, 49);

        &-1 {
            background-image: linear-gradient(rgba(19, 67, 63, 0.2), rgba(19, 67, 63, 0.2)), url('../../assets/images/airport.jpg');
            background-size: 160%;
            background-position: center;
        }

        &-2 {
            background-image: linear-gradient(rgba(19, 67, 63, 0.2), rgba(19, 67, 63, 0.2)), url('../../assets/images/summer.jpg');
            background-size: 100% 120%;
            background-position: center top;
        }

        &-3 {
            background-image: linear-gradient(rgba(19, 67, 63, 0.1), rgba(19, 67, 63, 0.1)), url('../../assets/images/tiananmen.JPG');
            background-size: cover;
            background-position: center;
        }

        &-4 {
            background-image: linear-gradient(rgba(19, 67, 63, 0.1), rgba(19, 67, 63, 0.1)), url('../../assets/images/greatwall.JPG');
            background-size: 110%;
            background-position: left center;
        }

        &-5 {
            background-image: linear-gradient(rgba(19, 67, 63, 0.1), rgba(19, 67, 63, 0.1)), url('../../assets/images/798.jpg');
            background-size: cover;
            background-position: center top 15%;
        }

        &-6 {
            background-image: linear-gradient(rgba(19, 67, 63, 0.1), rgba(19, 67, 63, 0.1)), url('../../assets/images/heaven.jpg');
            background-size: cover;
            background-position: center 50%;
        }

        &-7 {
            background-image: linear-gradient(rgba(19, 67, 63, 0.1), rgba(19, 67, 63, 0.1)), url('../../assets/images/cherry.JPG');
            background-size: cover;
            background-position: center;
        }

    }

    &__icon {
        width: 7rem;
        height: 8rem;
        position: absolute;

        &-left {
            left: 4rem;
            bottom: 5rem;
            transform: rotate(25deg);
        }

        &-right {
            top: 3rem;
            right: 4rem;
            transform: rotate(0);
        }

        &-1 {
            left: 40%;
            top: 3rem;
        }

        &-5 {
            transform: rotate(0);
            left: 4rem;
            bottom: 5rem;
            ;
        }

        &-7 {
            transform: rotate(5deg);
            left: 50%;
            bottom: 4rem;
            height: 7.5rem;
        }


    }

}

// media query

@media (max-width: 470px) {
    .trip {
        &__header {

            &-wrapper {
                margin-top: 12rem;
                width: 85%;
            }

            &-date {
                font-size: 1.5rem;
                font-weight: 400;
            }

            &-title {
                font-size: 4rem;
            }

            &-text {
                font-size: 2.3rem;
                margin-top: .5rem;
                width: 90%;
            }
        }

    }
}

@media (max-width: $breakpoint-xs) {

    .trip {
        &__main {
            &-sec {
                width: 85vw;
                order: 2;

                &-title {
                    font-size: 3rem;
                    margin-bottom: 1rem;
                }

                &-text {
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                }

                &-img1 {
                    background-position: left;
                    margin-top: 0;
                }

                &-img2,
                &-img1 {
                    height: 26rem;
                }
            }
        }

        &__detail {
            order: 1;
            margin-top: 3rem;

            &-title {
                display: none;
            }

            &-title2 {
                display: block;
                font-size: 4rem;
            }

            &-right {
                display: none;
            }

            &-left {
                width: 100%;
            }

        }
    }

    .itinerary {
        margin-top: 0;
        padding-bottom: 2.5rem;

        &__caption {
            font-size: 4rem;

            &-underline {
                display: none;
            }
        }

        &__wrapper {
            width: 100%;
            padding: 1.5rem 2.5rem;

            &-left,
            &-right {
                border-radius: 0;
            }
        }

        &__block {
            margin: 0;
            padding: 1rem 0;
            width: 100%;
        }

        &__img {
            display: none;
        }

        &__title {
            font-size: 2.5rem;
        }

        &__subtitle {
            font-size: 1.5rem;
        }

        &__item {
            font-size: 1.5rem;
            padding-bottom: 1rem;

            &:last-child {
                padding-bottom: .5rem;
            }
        }

        &__icon {
            width: 5rem;
            height: 6rem;
            top: 4rem;

            &-1 {
                left: 70%;
                transform: rotate(15deg);
            }

            &-2 {
                top: 1rem;
                right: 2.5rem;
                transform: rotate(-15deg);
            }

            &-3 {
                display: none;
            }

            &-4 {
                top: .6rem;
                transform: rotate(-20deg);
            }

            &-5 {
                display: none;
            }

            &-6 {
                display: none;
            }

            &-7 {
                left: 70%;
            }
        }
    }
}

@media (min-width: 471px) and (max-width: $breakpoint-xs) {
    .trip {
        &__header {

            &-wrapper {
                margin-top: 15rem;
                width: 85%;
            }

            &-date {
                font-size: 1.7rem;
                font-weight: 400;
            }

            &-title {
                font-size: 5rem;
            }

            &-text {
                font-size: 2.5rem;
                margin-top: .5rem;
            }
        }


    }
}

@media (min-width: 577px) and (max-width: $breakpoint-sm) {
    .trip {
        &__header {

            &-wrapper {
                margin-top: 16rem;
                width: 82%;
            }

            &-date {
                font-size: 1.8rem;
                font-weight: 400;
            }

            &-title {
                font-size: 6rem;
            }

            &-text {
                font-size: 2.5rem;
                margin-top: 1rem;
            }
        }

        &__main {
            &-sec {
                width: 82vw;

                &-img3 {
                    width: 100%;
                }

                &-title {
                    font-size: 4rem;
                    text-align: center;
                }

                &-text {
                    font-size: 1.9rem;
                    line-height: 2.4rem;
                }

                &-img1,
                &-img2 {
                    display: none;
                }

                &-img3 {
                    display: block;
                    height: 17rem;
                    background-image: linear-gradient(rgba(19, 67, 63, 0.25), rgba(19, 67, 63, 0.25)), url('../../assets/images/group_food.jpg');
                    background-size: cover;
                    background-position: center top 60%;
                }

            }
        }

        &__detail {
            margin-top: 7rem;

            &-title {
                font-size: 5rem;
            }

            &-left {
                width: 70%;
            }

            &-right {
                width: 25%;
            }
        }

    }

    .itinerary {
        margin-top: 0;

        &__caption {
            font-size: 5rem;

            &-underline {
                display: none;
            }
        }

        &__wrapper {
            width: 85%;
            padding: 1.5rem 2.5rem;
            gap: 1rem;
        }

        &__block {
            margin: 0;
            padding: 1rem 0;
            width: 70%;
        }

        &__img {
            width: 15rem;
            height: 15rem;
            border-radius: 50%;
            align-self: center;

            &-2 {
                background-size: cover;
            }
        }

        &__title {
            font-size: 2.5rem;
        }

        &__subtitle {
            font-size: 1.5rem;
        }

        &__item {
            font-size: 1.5rem;
            padding-bottom: 1rem;

            &:last-child {
                padding-bottom: .5rem;
            }
        }

        &__icon {
            display: none;
        }
    }

}

@media (max-width: $breakpoint-sm) {
    .trip {
        &__main {
            &-sec {
                margin-top: 3.5rem;
                flex-direction: column;

                &-left,
                &-right,
                &-img {
                    width: 100%;
                }

                &-right {
                    margin-top: 1rem;
                }

                &-img {
                    order: 2;
                    height: 15rem;
                    background-position: 74% 45%;
                    margin-top: 2rem;
                }

                &-title {
                    margin-bottom: 1rem;
                }

                &-text {
                    width: 100%;
                    margin: 1rem 0;
                }
            }
        }

        &__detail {
            &-info {
                font-size: 1.8rem;

                &-list {
                    margin-left: 3.5rem;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-md) {
    .trip {
        &__detail {
            flex-direction: column;
            width: 85vw;

            &-wrapper {
                width: 100%;
            }

            &-title {
                margin: 0;
            }


            &-block {
                margin: 2rem 0;
                gap: 1rem;
                padding: 1rem;

                &-btns {
                    margin: 0;
                    padding: 1rem;
                }
            }

            &-icon {
                &-img {
                    width: 3rem;
                    height: 3rem;
                    margin-left: .5rem;
                }
            }

            &-price {
                margin-left: 0;
                flex-direction: column;

                &-block {
                    width: 100%;
                    margin-bottom: 2rem;
                    padding-bottom: 1rem;
                }

                &-info {
                    padding: 1rem;
                }
            }

            &-btn {
                width: 10rem;
                height: 4rem;
                font-size: 100%;
            }
        }
    }
}


@media (min-width: 769px) and (max-width: $breakpoint-md) {
    .trip {
        &__header {

            &-wrapper {
                margin-top: 18rem;
                width: 76%;
            }

            &-date {
                font-size: 2rem;
                font-weight: 400;
            }

            &-title {
                font-size: 8rem;
            }

            &-text {
                font-size: 2.8rem;
                margin-top: .5rem;
            }
        }

        &__detail {
            margin-top: 7rem;
            width: 82%;

            &-info {
                font-size: 2.2rem;

                &-list {
                    margin-left: 3.5rem;
                }
            }

            &-left {
                width: 65%;
            }

            &-right {
                width: 28%;
            }

        }
    }

    .itinerary {
        margin-top: 2rem;

        &__caption {
            font-size: 6rem;
        }

        &__wrapper {
            width: 85%;
            justify-content: space-between;

            &-left {
                right: 0;
            }
        }

        &__block {
            margin: 0;
            padding: 3rem 4rem;
            width: 65%;
        }

        &__img {
            width: 32%;

            &-2 {
                background-size: cover;
            }
        }

        &__title {
            font-size: 2.8rem;
        }

        &__subtitle {
            font-size: 2rem;
        }

        &__item {
            font-size: 2.2rem;
            padding-bottom: 1rem;

            &:last-child {
                padding-bottom: .5rem;
            }
        }

        &__icon {
            display: none;
        }
    }
}

@media (min-width: 769px) and (max-width: $breakpoint-lg) {
    .trip {
        &__main {
            &-sec {
                width: 82vw;
                margin-top: 5rem;

                &-left {
                    width: 49%;
                }

                &-right {
                    width: 48%;
                }

                &-title {
                    font-size: 3.6rem;
                    margin-bottom: 1rem;
                }

                &-text {
                    width: 100%;
                    font-size: 2rem;
                    margin: 1rem 0;
                }

                &-img {
                    align-self: center;
                    height: 30rem;
                    background-position: 85% 45%;
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: $breakpoint-lg) {
    .trip {
        &__header {

            &-wrapper {
                margin-top: 23rem;
                width: 76%;
            }

            &-date {
                font-size: 2.3rem;
            }

            &-title {
                font-size: 9rem;
            }

            &-text {
                font-size: 4rem;
                margin-top: .5rem;
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1151px) {
    .trip {
        &__detail {
            margin-top: 9rem;
            justify-content: center;
            width: 88%;

            &-wrapper {
                width: 100%;
            }

            &-info {
                font-size: 3rem;
            }

            &-left {
                width: 65%;
            }

            &-right {
                width: 28%;
            }

        }
    }

    .itinerary {
        &__icon {

            &-2,
            &-5,
            &-6 {
                display: none;
            }
        }
    }
}