@use '../../styles/global.scss' as *;
@use '../../styles/variables.scss' as *;


.swiper {
    width: 45vw;
    height: 44vw;
    padding: 3rem 1rem;

    &-slide {
        background-position: center;
        background-size: cover;
        height: 60rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        & img {
            display: block;
            width: 90%;
            height: 33vw;
            object-fit: cover;
        }
    }
}

@media(max-width: $breakpoint-sm) {
    .swiper {
        width: 100vw;
        height: 36rem;

        &-slide {
            height: 30rem;

            & img {
                width: 75vw;
                height: 30rem;
            }
        }

    }
}

@media (min-width:769px) and (max-width: $breakpoint-md) {
    .swiper {
        width: 46vw;
        height: 59rem;
        padding-top: 15rem;

        &-slide {
            height: 30rem;

            & img {
                width: 35vw;
                height: 50rem;
            }
        }

    }
}

@media (min-width: 991px) and (max-width: $breakpoint-xl) {
    .swiper {
        width: 46vw;
        height: 70rem;
        padding-top: 20rem;

        &-slide {
            height: 30rem;

            & img {
                width: 34vw;
                height: 60rem;
            }
        }

    }
}