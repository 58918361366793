@use '../../styles/global.scss' as *;
@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;

.nav {
    padding: 2rem 10rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100vw;

    &-reg {
        position: absolute;
    }

    &-stick {
        background-color: rgba(86, 150, 187, 0.785);
        position: fixed;
        z-index: 101;
    }


    &__right {
        display: flex;
        gap: 2rem;


        @media (max-width: 767px) {
            display: none;
        }
    }

    &__btn {
        font-size: 2.5rem;
        background-color: transparent;
        font-weight: 500;
        font-family: Mitr, Times, serif;
        text-transform: uppercase;
        color: white;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        border: none;
        cursor: pointer;
        position: relative;
        padding-bottom: .5rem;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 4px;
            background-color: white;
            transition: width 0.3s ease;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }

        &:hover::after {
            width: 100%;
        }

        &-logo {
            background-color: transparent;
            font-size: 4rem;
            font-family: Yeseva, Times, serif;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
            color: white;
            border: none;
            cursor: pointer;

        }

        &.active {
            color: red;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        }


    }

    &__responsive {
        display: none;


        @media (max-width: 766px) {
            display: block;
        }

        &-overlay {
            background-color: rgba(255, 255, 255, 0.5);
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99;

        }

        &-icon {
            width: 4rem;
            position: fixed;
            top: 2rem;
            right: 5rem;
            z-index: 99;

            &:hover {
                width: 4.1rem;
                height: 4.1rem;
            }

            @media (max-width: $breakpoint-xs) {
                width: 3rem;

                &:hover {
                    width: 3.1rem;
                    height: 3.2rem;
                }
            }
        }

        &-navbar {
            width: 45vw;
            height: 100vh;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            display: none;
            z-index: 100;

            @media (max-width: 575px) {
                width: 100vw;
            }

            @media (max-width: 766px) {
                display: block;
            }
        }

        &-list {
            display: flex;
            flex-direction: column;
            margin: 7rem 0;
            align-items: center;
        }

        &-btn {
            margin: 1rem 0;
            color: $btnBlue;
            font-size: 3rem;
            font-weight: bold;
            text-shadow: none;

            &::after {
                box-shadow: none;
            }

            &:hover {
                font-size: 3.3rem;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);

            }

        }

        &-exit {
            width: 3.5rem;
            position: absolute;
            right: 2rem;
            top: 2rem;
            cursor: pointer;
        }
    }
}

@media (min-width: 320px) and (max-width: 992px) {
    .nav {
        padding: 2rem 5rem;

        &__btn {
            font-size: 2.2rem;

            &-logo {
                font-size: 3rem;
            }
        }
    }

}

@media (max-width: 379px) {
    .nav {
        padding: 2rem 3rem;

        &__btn {

            &-logo {
                font-size: 2.5rem;
            }
        }

        &__responsive {
            &-icon {
                right: 3rem;
            }
        }
    }

}