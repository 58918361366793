@use '../../styles/global.scss' as *;
@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;


.reviews {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    &__title {
        font-size: 7rem;
        margin: 0 0 3rem 0;
        font-family: Voltaire, 'Times New Roman', Times, serif;
        color: $textGreyblue;
        text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);

        @media (min-width: 769px) and (max-width: $breakpoint-xl) {
            font-size: 6rem;
        }

        @media (max-width: $breakpoint-sm) {
            font-size: 4rem;
            text-align: center;
        }
    }

}