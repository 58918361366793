@use '../../styles/global.scss' as *;
@use '../../styles/variables.scss' as *;

.input {
    width: 47%;
    height: 5rem;
    margin-bottom: 2rem;
    border-radius: 5px;
    border: none;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    font-size: 1.8rem;

    @media (min-width: 320px) and (max-width: $breakpoint-md) {
        width: 100%;
    }

    &__radio {
        margin: 0 .5rem 2rem 0;
    }
}