@font-face {
    font-family: 'Yeseva';
    src: url('../assets/fonts/YesevaOne-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mitr';
    src: url('../assets/fonts/Mitr-Medium.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mitr';
    src: url('../assets/fonts/Mitr-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Mitr';
    src: url('../assets/fonts/Mitr-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: bold;
}

@font-face {
    font-family: 'Voltaire';
    src: url('../assets/fonts/Voltaire-Regular.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
    font-style: normal;
}