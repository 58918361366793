@use '../../styles/global.scss' as *;
@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;


.home {
    background-color: $bgBlue;

    &__header {
        height: 84.5vh;
        background-image: url(../../assets/images/greatwall_prince.jpg);
        background-size: cover;
        background-position: center 40%;
        padding-top: 12%;
        padding-left: 20%;
        border-bottom-left-radius: 22rem;

        @include headerHeight;

        @media (min-width: 380px) and (max-width: $breakpoint-xs) {
            padding-left: 5rem;
        }

        @media (max-width: 379px) {
            padding-left: 3rem;
        }
    }

    &__title {
        @include redTitle;

        @media (max-width: $breakpoint-md) {
            font-size: 7rem;
        }

        @media (max-width: $breakpoint-xs) {
            font-size: 5.5rem;
        }

        @media (max-width: 400px) {
            font-size: 4.5rem;
        }

        &-one {
            @media (max-width: $breakpoint-xs) {
                margin-top: 2rem;
            }
        }

        &-three {
            margin-bottom: 4rem;

            @media (max-width: $breakpoint-md) {
                margin-bottom: 1.5rem;
            }
        }
    }

    &__btn,
    &__btn *,
    &__btn :after,
    &__btn :before,
    &__btn:after,
    &__btn:before {
        border: 0 solid;
        box-sizing: border-box;
    }

    &__btn {
        width: 16rem;
        height: 5rem;
        font-size: 100%;
        color: white;
        letter-spacing: .1rem;
        -webkit-tap-highlight-color: transparent;
        -webkit-appearance: button;
        background-color: rgb(86, 150, 187, .8);
        background-image: none;
        cursor: pointer;
        font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
            Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
            Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        font-size: 100%;
        margin: 0;
        -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
        padding: 0;

        &:disabled {
            cursor: default;
        }

        &:-moz-focusring {
            outline: auto;
        }

        & svg {
            display: block;
            vertical-align: middle;
        }

        & [hidden] {
            display: none;
        }

        box-shadow: inset 0 0 0 3px white;
        box-sizing: border-box;
        font-family: Mitr,
        'Times New Roman',
        Times,
        serif;
        display: block;
        font-weight: 800;
        padding: 1.2rem 3rem;
        position: relative;
        text-transform: uppercase;
        transition: color 0.1s linear;


        &:after,
        &:before {
            content: "";
            position: absolute;
        }

        &:before {
            border-bottom: var(--border);
            border-top: var(--border);
            height: 100%;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
        }

        &:hover:before {
            --border: 4px solid rgb(67, 116, 145);
            -webkit-animation: border-top-and-bottom 1s forwards;
            animation: border-top-and-bottom 1s forwards;
        }

        &:after {
            border-left: var(--border);
            border-right: var(--border);
            bottom: 50%;
            right: 0;
            transform: translateY(50%);
            width: 100%;
            z-index: -1;
        }

        &:hover:after {
            --border: 4px solid rgb(67, 116, 145);
            -webkit-animation: border-right-and-left 1s forwards;
            animation: border-right-and-left 1s forwards;
        }

        @media (max-width: $breakpoint-xs) {
            width: 12rem;
            height: 4.5rem;
        }

        @media (min-width: 577px) and (max-width: $breakpoint-sm) {
            margin-top: -1rem;
        }

        @media (min-width: 769px) and (max-width: $breakpoint-md) {
            margin-top: -1.5rem;
        }

        @media (min-width: 993px) and (max-width: $breakpoint-lg) {
            margin-top: -2.5rem;
        }
    }

    &__main {
        background-color: $bgBlue;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &__sec,
    &__about {
        text-align: center;
    }

    &__sec {
        width: 65vw;

        &-title {
            font-family: Voltaire, Yeseva, Roboto, 'Times New Roman', Times, serif;
            margin: 7rem 0 3rem 0;
            color: white;
            font-size: 6rem;

            @media (max-width: $breakpoint-md) {
                margin-top: 6rem;
            }

            @media (max-width: $breakpoint-sm) {
                margin-top: 4rem;
            }

            @media (max-width: $breakpoint-xs) {
                font-size: 4rem;
                margin: 3rem 0;
            }
        }

        &-text {
            font-size: 2.5rem;
            color: rgb(218, 220, 223);

            @media (max-width: $breakpoint-md) {
                font-size: 2.2rem;
            }

            @media (max-width: $breakpoint-xs) {
                font-size: 2rem;
            }
        }

        &-date {
            margin: 2rem 0 0 0;
            color: white;
            font-size: 2rem;
            font-weight: bold;
            text-transform: uppercase;

            @media (max-width: $breakpoint-xs) {
                font-size: 1.8rem;
            }
        }


        &-btn {
            @include blueBtn
        }


    }

    &__slide {
        margin: 3rem 0 5rem 0;
        width: 78vw;
        display: flex;
        justify-content: space-between;

        @media (max-width: $breakpoint-sm) {
            flex-wrap: wrap;
            margin-bottom: 0;
        }

        &-hashlink {
            text-decoration: none;
            color: inherit;
            display: block;
        }


        &-img {
            width: 23%;
            border-radius: 5px;
            background-size: cover;
            background-position: center;
            height: 34rem;
            cursor: pointer;

            @media (max-width: $breakpoint-sm) {
                width: 48%;
                margin-bottom: 3rem;
                height: 24rem;

            }

            @media (max-width: $breakpoint-xs) {
                width: 100%;
                height: 14rem;
            }
        }

        &-img1 {
            background-image: linear-gradient(rgba(86, 150, 187, 0.4), rgba(116, 171, 205, 0.2)), url('../../assets/images/duck.jpg');
            background-position: 25% center;
        }

        &-img2 {
            background-image: linear-gradient(rgba(86, 150, 187, 0.4), rgba(133, 194, 233, 0.2)), url('../../assets/images/heaven.jpg');

            @media (max-width: $breakpoint-xs) {
                background-position: center bottom 57%;
            }
        }

        &-img3 {
            background-image: linear-gradient(rgba(86, 150, 187, 0.4), rgba(133, 194, 233, 0.2)), url('../../assets/images/nico.png');

            @media (max-width: $breakpoint-md) {
                background-position: center left 38%;
            }

            @media (max-width: $breakpoint-xs) {
                background-position: center bottom 24%;
            }
        }

        &-img4 {
            background-image: linear-gradient(rgba(86, 150, 187, 0.3), rgba(133, 194, 233, 0.2)), url('../../assets/images/joe.JPG');
            background-position: 30% center;

            @media (max-width: $breakpoint-xs) {
                background-position: center bottom 20%;
            }
        }

        &-caption {
            font-family: Voltaire, Yeseva, Roboto, 'Times New Roman', Times, serif;
            color: white;
            font-size: 3.5rem;
            text-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
            padding: 3rem 2rem;
            text-transform: uppercase;
            width: 60%;

            @media (max-width: $breakpoint-md) {
                font-size: 2.8rem;
                padding: 3rem 1rem;
            }
        }
    }

    &__about {
        width: 100vw;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        &-wrapper {
            width: 58vw;

        }

        &-title {
            color: white;

            @media (max-width: $breakpoint-xs) {
                margin-bottom: 2rem;
            }
        }

        &-text {
            color: rgb(218, 220, 223);

            @media (max-width: $breakpoint-xs) {
                font-size: 1.9rem;
            }


            &-bold {
                font-style: italic;
                // color: $bigRed;
                color: white;
                font-weight: bold;
                margin-top: 1.5rem;
                font-size: 3rem;
                text-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);


                @media (min-width: 320px) and (max-width: $breakpoint-xs) {
                    font-size: 2.2rem;
                }

                @media (min-width: 575px) and (max-width: $breakpoint-sm) {
                    font-size: 2.5rem;
                }

                @media (min-width: 769px) and (max-width: $breakpoint-md) {
                    font-size: 2.7rem;
                }
            }
        }

        &-btn {
            width: 20rem;
            height: 6rem;


            @media (max-width: $breakpoint-xs) {
                width: 15rem;
                height: 5rem;
            }
        }

        &-btn:hover {
            border: 3.7px solid $btnBlue;
        }

        &-slide {
            width: 60vw;
            margin: 5rem 0 8rem 0;

            @media (max-width: $breakpoint-sm) {
                width: 80vw;
            }
        }

        &-img {
            width: 100%;
            height: 58rem;
            border-radius: 5px;
            background-size: cover;
            background-position: center;
            border-radius: 5px;

            @media (min-width: 577px) and (max-width: $breakpoint-sm) {
                width: 100%;
                height: 38rem;
            }

            @media (max-width: $breakpoint-xs) {
                width: 100%;
                height: 30rem;
            }
        }

        // &-img1 {
        //     background-image: linear-gradient(rgba(86, 150, 187, 0.1), rgba(116, 171, 205, 0.2)), url('../../assets/images/heart_wall.jpeg');
        //     background-position: 10% center;
        //     transform: rotate(-10deg);
        //     z-index: 1;
        //     box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

        //     @media (max-width: $breakpoint-sm) {
        //         display: none;
        //     }
        // }

        &-img2 {
            background-image: linear-gradient(rgba(86, 150, 187, 0.1), rgba(116, 171, 205, 0.2)), url('../../assets/images/group_jingshan.jpeg');
            background-position: 60% center;
            z-index: 2;
            margin-top: -3rem;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

            @media (max-width: $breakpoint-sm) {
                background-position: center bottom 10%;
            }
        }


        // &-img3 {
        //     background-image: linear-gradient(rgba(86, 150, 187, 0.1), rgba(116, 171, 205, 0.2)), url('../../assets/images/maria.jpg');
        //     background-position: 20% center;
        //     transform: rotate(10deg);
        //     z-index: 3;
        //     box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

        //     @media (max-width: $breakpoint-sm) {
        //         display: none;
        //     }
        // }
    }

    &__reviews {
        // display: none;
        display: flex;
        justify-content: center;
        background-image: linear-gradient(rgba(225, 231, 231, 0.7), rgba(214, 224, 223, 0.7)), url('../../assets/images/cherry.JPG');
        background-position: center top 60%;
        background-size: cover;
        width: 100%;
        padding: 6rem 6rem 8rem 6rem;
        margin: 3rem auto;
        margin-top: 6rem;
        // height: 73rem;

        @media (max-width: $breakpoint-xs) {
            padding: 4rem 3rem 4rem 3rem;
        }

        // @media (min-width:390px) and (max-width:499px) {
        //     height: 65rem;
        // }

        // @media (min-width:500px) and (max-width:$breakpoint-xs) {
        //     height: 57rem;
        // }

        & .slick-arrow.slick-prev,
        & .slick-arrow.slick-next {
            height: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            border-radius: 50%;
        }
    }

}

@-webkit-keyframes border-top-and-bottom {
    0% {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes border-top-and-bottom {
    0% {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@-webkit-keyframes border-right-and-left {
    0% {
        height: 0;
    }

    to {
        height: 100%;
    }
}

@keyframes border-right-and-left {
    0% {
        height: 0;
    }

    to {
        height: 100%;
    }
}


@media (max-width: $breakpoint-md) {

    .home__sec,
    .home__about-wrapper {
        width: 75vw;
    }
}

@media (max-width: $breakpoint-xs) {

    .home__sec,
    .home__about-wrapper {
        width: 80vw;
    }
}