@use '../../styles/global.scss' as *;
@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;


.footer {
    height: 10rem;
    width: 100vw;
    display: flex;
    align-items: center;
    padding: 2rem 5rem;
    justify-content: space-between;

    &__logo {
        color: $darkText;
        width: 27%;
        font-size: 3rem;
    }

    &__links {
        list-style: none;
        display: flex;
        color: $darkText;
        text-transform: uppercase;
        justify-content: space-between;
        width: 55%;
    }

    &__item {
        font-size: 1.7rem;
        font-weight: bold;
        cursor: pointer;
    }

    &__socials {
        display: flex;
        gap: 1rem;
        width: 15%;
        justify-content: center;

        &-icon {
            width: 2rem;
        }
    }

}

@media (max-width: $breakpoint-xs) {
    .footer {
        flex-direction: column;
        justify-content: flex-end;
        height: 13rem;

        &__logo {
            font-size: 2rem;
            text-align: center;
            width: 100%;
            margin-bottom: .5rem;
        }

        &__links {
            width: 100%;
            flex-wrap: wrap;
            gap: 1rem;
            margin-bottom: .5rem;
            justify-content: space-evenly;
        }

        &__item {
            font-size: 1.5rem;
            font-weight: 300;
        }

    }
}

@media (min-width: 577px) and (max-width: $breakpoint-md) {
    .footer {
        flex-direction: column;
        height: 12rem;

        &__logo {
            font-size: 2.3rem;
            margin-bottom: 1rem;
            text-align: center;
            width: 100%;
        }

        &__links {
            width: 95%;
            margin-bottom: 1rem;
        }

        &__item {
            font-size: 1.5rem;
        }

        &__socials {
            width: 100%;
        }
    }
}

@media (min-width: 769px) and (max-width: $breakpoint-md) {
    .footer {
        &__links {
            width: 70%;
        }
    }
}