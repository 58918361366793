@use '../../styles/global.scss' as *;
@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;


.about {
    background-color: rgba(85, 0, 18, .25);

    &__header {
        height: 84.5vh;
        background-image: linear-gradient(rgba(85, 0, 18, 0.1), rgba(85, 0, 18, 0.05)), url('../../assets/images/jingshan.jpg');
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom-left-radius: 22rem;

        @include headerHeight
    }

    &__title {
        margin-top: -1rem;
        text-align: right;
        margin-left: -10rem;
        @include redTitle;
        text-align: center;

        &-1 {
            display: block;
            margin-left: 20rem;
        }

        &-2 {
            margin-left: 40rem;
        }

    }

    &__main {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &__sec {
        margin: 10rem 0;
        width: 78vw;
        height: 72rem;
        display: flex;
        justify-content: space-between;

        &-left,
        &-right {
            width: 45%;
        }

        &-left {
            background-image: linear-gradient(rgba(238, 229, 230, 0.4), rgba(85, 0, 18, 0.2)), url('../../assets/images/guilin.JPG');
            background-size: cover;
            background-position: center;
            border-radius: 5px;
        }

        &-subtitle {
            @include blueSub;
            font-size: 5rem;
        }

        &-text {
            margin: 2rem 0;
            font-size: 2.2rem;
            color: $darkText;
            line-height: 2.8rem;
            width: 95%;
            text-indent: 3rem;
        }

    }

    &__two {
        width: 100%;
        margin-top: 5rem;
        display: flex;
        justify-content: space-between;
        position: relative;

        &-left {
            width: 30%;
            background-image: linear-gradient(rgba(85, 0, 18, 0.1), rgba(124, 113, 116, 0.2)), url('../../assets/images/xian_group.jpg');
            height: 35rem;
            background-position: center left;
            background-size: cover;
            align-self: flex-end;
            z-index: 1;
            border-radius: 5px;
        }

        &-mid {
            background-color: rgb(236, 234, 234);
            width: 40%;
            z-index: 2;
            position: absolute;
            align-self: center;
            left: 25%;
            padding: 5rem;
            border-radius: 5px;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2)
        }

        &-right {
            width: 40%;
            height: 75rem;
            background-image: linear-gradient(rgba(109, 85, 90, 0.2), rgba(85, 0, 18, 0.1)), url('../../assets/images/group_forbidden.JPG');
            background-position: center;
            background-size: cover;
            z-index: 1;
            border-radius: 5px;
        }

        &-caption {
            @include blueSub;
            font-size: 3rem;
            margin-bottom: 3rem;
        }

        &-text {
            margin: 2rem 0;
            font-size: 2.2rem;
            color: $darkText;
            line-height: 2.8rem;
        }

        &-btn {
            @include blueBtn;
            margin: 1rem;

            &:hover {
                border: 3.7px solid $btnBlue;
            }

            &-wrapper {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    &__carousel {
        margin: 12rem 8rem;

        &-title {
            @include blueSub;
            font-size: 5rem;
            text-align: center;
            margin-bottom: 3rem;
        }

        &-content {
            display: flex;
            // width: 75vw;
            align-items: flex-start;
            justify-content: center;
            gap: 2rem;
        }

        &-description {
            margin-top: 4rem;
            width: 38%;
        }

        &-subtitle {
            font-family: Voltaire, 'Times New Roman', Times, serif;
            text-align: center;
            color: $darkText;
            font-size: 3rem;
            margin: 2rem 0 1rem 0;

        }

        &-text {
            margin: 2rem 0;
            font-size: 2.2rem;
            color: $darkText;
            line-height: 2.8rem;
            text-indent: 3rem;
            text-align: auto;
        }

        &-swiper {
            width: 48%;
        }
    }
}


// media query 
@media (max-width: 379px) {
    .about {
        &__title {
            font-size: 4rem;
            margin-left: -3rem;
            margin-top: 2rem;

            &-1 {
                margin-left: 4rem;
            }

            &-2 {
                margin-left: 10rem;
            }
        }

        &__header {
            background-size: 180%;
            height: 30rem;
        }

    }
}


@media (max-width: $breakpoint-xs) {
    .about {
        &__sec {
            margin-top: 4.5rem;
            margin-bottom: 4rem;
            flex-direction: column;
            width: 82vw;
            position: relative;
            height: 63rem;

            &-subtitle {
                font-size: 2.6rem;
                margin-bottom: 2rem;
            }

            &-left {
                width: 120vw;
                height: 10rem;
                position: absolute;
                background-position: center top 29%;
                border-radius: 0;
                left: -5rem;
                opacity: .85;
                bottom: -1rem;
            }

            &-right {
                width: 100%;
            }

            &-text {
                font-size: 1.6rem;
                margin: 1rem 0;
                width: 100%;
                line-height: 2.2rem;
            }
        }

        &__two {
            height: 80rem;
            margin-top: 2.5rem;

            &-mid {
                width: 80%;
                position: absolute;
                left: 10%;
                top: 0;
                padding: 2.5rem;
            }

            &-left {
                display: none;
            }

            &-right {
                width: 100%;
                position: absolute;
                height: 25rem;
                top: 48rem;
            }

            &-caption {
                font-size: 1.8rem;
                width: 100%;
                margin-bottom: 1.8rem;
            }

            &-text {
                margin: 1rem 0;
                font-size: 1.6rem;
                line-height: 2.2rem;
                text-indent: 1.8rem;
            }

            &-btn {
                width: 10rem;
                height: 3.5rem;
                font-size: 100%;
            }
        }

        &__carousel {
            margin: 0;
            margin-bottom: 3rem;

            &-title {
                font-size: 2.6rem;
                margin: 1rem 2rem;
            }

            &-content {
                flex-direction: column;
                align-items: center;
                gap: 0;
            }

            &-description {
                width: 82vw;
                margin-top: 2rem;
            }

            &-subtitle {
                font-size: 2.2rem;
                margin-top: 0;
            }

            &-text {
                font-size: 1.6rem;
                margin: 1rem 0;
                line-height: 2.2rem;
            }

            &-swiper {
                width: 100%;
            }
        }
    }
}

@media (max-width: 445px) {
    .about {
        &__sec {
            margin-top: 3rem;
            height: 65rem;

            &-subtitle {
                margin-bottom: 0;
            }
        }

        &__two {
            &-right {
                top: 51rem;
            }
        }

        &__carousel {
            margin: 2rem 0;
        }
    }
}

@media (max-width: 405px) {
    .about {
        &__sec {
            height: 70rem;
        }

        &__two {
            &-right {
                top: 53rem;
            }
        }

    }
}

@media (max-width: 385px) {
    .about {
        &__sec {
            height: 75rem;
        }

        &__two {
            &-right {
                top: 55rem;
            }
        }

        &__carousel {
            margin: 5rem 0;
        }
    }
}

@media (max-width: 370px) {
    .about {
        &__sec {
            height: 78rem;
        }

        &__two {
            &-right {
                top: 57rem;
            }
        }

    }

}

@media (max-width: 330px) {
    .about {
        &__sec {
            height: 84rem;
        }

        &__two {
            &-right {
                top: 63rem;
            }
        }

        &__carousel {
            margin: 10rem 0 5rem;
        }
    }

}

@media (min-width: 380px) and (max-width: $breakpoint-xs) {
    .about {

        &__title {
            font-size: 4.5rem;
            margin-left: -2rem;
            margin-top: 1rem;

            &-1 {
                margin-left: 5rem;
            }

            &-2 {
                margin-left: 12rem;
            }
        }

        &__header {
            background-size: 140%;
            height: 38rem;
        }
    }
}

@media (min-width: 577px) and (max-width: $breakpoint-sm) {
    .about {
        &__title {
            font-size: 6rem;
            margin-left: -2rem;
            margin-top: -5rem;

            &-1 {
                margin-left: 4rem;
            }

            &-2 {
                margin-left: 12rem;
            }
        }

        &__sec {
            width: 83vw;
            margin-top: 6rem;
            height: 80rem;

            &-subtitle {
                font-size: 2.8rem;
            }

            &-left {
                width: 35%;
                background-position: center left 30%;
            }

            &-right {
                width: 59%;
            }

            &-text {
                font-size: 1.7rem;
                margin: 1rem 0;
                width: 100%;
            }
        }

        &__two {
            margin-top: 2rem;
            height: 83rem;

            &-mid {
                width: 80%;
                position: absolute;
                left: 10%;
                top: 12rem;
                padding: 2.5rem;
            }

            &-left {
                width: 55%;
                height: 17rem;
                position: absolute;
                top: -2rem;
                background-size: cover;
                background-position: center;
            }

            &-right {
                width: 100%;
                position: absolute;
                height: 46rem;
                top: 46rem;
            }

            &-caption {
                font-size: 2rem;
                margin-bottom: 1.8rem;
            }

            &-text {
                margin: 1rem 0;
                font-size: 1.7rem;
                line-height: 2.2rem;
                text-indent: 2rem;
            }

            &-btn {
                width: 10rem;
                height: 3.5rem;
                font-size: 100%;
            }
        }

        &__carousel {
            margin-bottom: 5rem;

            &-title {
                font-size: 3rem;
                margin: 1rem 2rem;
            }

            &-content {
                flex-direction: column;
                align-items: center;
                gap: 0;
            }

            &-description {
                width: 82vw;
                margin-top: 2rem;
            }

            &-subtitle {
                font-size: 2.2rem;
                margin-top: 0;
            }

            &-text {
                font-size: 1.7rem;
                margin: 1rem 0;
                line-height: 2.2rem;
            }

            &-swiper {
                width: 100%;
            }
        }

    }

}



@media (min-width: 769px) and (max-width: $breakpoint-md) {
    .about {
        &__title {
            font-size: 6.8rem;
            margin-left: -18rem;
            margin-top: -3rem;
        }

        &__sec {
            width: 80vw;
            margin-top: 7rem;

            &-subtitle {
                font-size: 3.5rem;
            }

            &-left {
                width: 35%;
                background-position: center left 25%;
            }

            &-right {
                width: 59%;
            }

            &-text {
                font-size: 1.8rem;
                margin: 1rem 0;
                width: 100%;
            }
        }

        .about {
            &__two {
                margin-top: 5rem;
                height: 73rem;

                &-mid {
                    width: 60%;
                    position: absolute;
                    left: 15%;
                    top: 12rem;
                    padding: 4rem;
                }

                &-left {
                    width: 35%;
                    height: 25rem;
                    position: absolute;
                    top: -5rem;
                    background-size: cover;
                    background-position: center;
                }

                &-right {
                    background-position: left;
                    width: 46%;
                    position: absolute;
                    right: 0;
                    top: 3rem;
                }

                &-text {
                    margin: 1.5rem 0;
                    font-size: 2rem;
                    text-indent: 2.5rem;
                }
            }
        }

        &__carousel {
            margin: 12rem 3rem;

            &-title {
                font-size: 3.5rem;
                margin: 1rem 2rem;
            }

            &-content {
                gap: 1rem;
            }

            &-description {
                width: 40%;
            }

            &-subtitle {
                font-size: 2.5rem;
                margin-top: 0;
            }

            &-text {
                font-size: 2rem;
                margin: 1rem 0;
                line-height: 2.5rem;
                width: 100%;
            }

            &-swiper {
                width: 48%;
            }
        }
    }
}



@media (min-width: 991px) and (max-width: $breakpoint-lg) {
    .about {
        &__sec {

            &-left {
                width: 37%;
                background-position: center left 30%;
            }

            &-right {
                width: 57%;
            }


        }
    }
}

@media (min-width: 991px) and (max-width: $breakpoint-xl) {
    .about {
        &__title {
            margin-left: -18rem;
            margin-top: -9rem;
        }

        &__sec {
            &-subtitle {
                font-size: 4rem;
            }

            &-text {
                font-size: 2.1rem;
                margin: 1.5rem 0;
            }
        }

        &__two {

            &-mid {
                top: 10rem;
                width: 60%;
                left: 15%;
            }

            &-right {
                height: 82rem;
            }

            &-left {
                width: 35%;
                height: 23rem;
                background-size: cover;
                background-position: center;
                margin-bottom: -9rem;
            }
        }
    }

}

@media (min-width: $breakpoint-lg) and (max-width: 1279px) {
    .about {
        &__sec {
            height: 77.5rem;

            &-left,
            &-right {
                width: 47%;
            }

            &-left {
                background-position: center left 30%;
            }

            &-text {
                font-size: 2.1rem;
                margin: 1.5rem 0;
            }
        }
    }
}


@media (min-width: $breakpoint-xl) and (max-width: 1364px) {
    .about {

        &__sec {
            height: 77rem;

            &-subtitle {
                font-size: 4.5rem;
            }

            &-text {
                width: 100%;
            }

            &-left {
                background-position: center left 35%;
            }
        }
    }
}