@use '../../styles/global.scss' as *;
@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;


.faq {

    background-color: rgb(186, 204, 196);

    &__header {
        height: 84.5vh;
        background-image: linear-gradient(rgba(186, 204, 196, 0.1), rgba(186, 204, 196, 0.25)), url('../../assets/images/lotus.jpg');
        background-size: cover;
        background-position: center 30%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        border-bottom-left-radius: 22rem;
        @include headerHeight;

        &-wrapper {
            width: 85%;
        }

        &-title {
            @include redTitle;
            margin: 0 10rem 3rem;
            text-align: right;
            color: rgb(228 81 149);
        }

    }

    &__main {
        margin-top: 10rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 5rem;

        &-title {
            font-family: Yeseva, 'Times New Roman', Times, serif;
            font-size: 7rem;
            text-align: center;
            color: $textGreyblue;
            margin-bottom: 5rem;
        }

        &-list {
            width: 78vw;
            list-style: none;
        }

        &-item {
            margin: 2rem 0;
        }

        &-subtitle {
            font-style: italic;
            color: $bigRed;
            font-size: 2rem;
        }

        &-question {
            font-size: 3rem;
            font-family: Voltaire, 'Times New Roman', Times, serif;
            margin: 1rem 0;
        }

        &-answer {
            margin: 1rem 0;
            font-size: 2.2rem;
            line-height: 3.2rem;

            &-item {
                list-style: number;
                margin-left: 2.5rem;
            }

            &-contact {
                text-align: center;
                font-style: italic;
                font-weight: bold;
            }
        }

        &-link {
            font-style: italic;
            text-decoration: none;
        }
    }

}

// media query

@media (max-width: $breakpoint-xs) {

    .faq {

        &__header {
            justify-content: flex-start;

            &-title {
                font-size: 5rem;
                margin-left: 4rem;
                margin-bottom: 3rem;
                text-align: left;
            }

            &-item {
                font-size: 2rem;
            }
        }

        &__main {
            margin-top: 3rem;

            &-title {
                font-size: 3rem;
                padding: 0 2rem;
                margin-bottom: 3rem;
            }

            &-question {
                font-size: 2.5rem;
            }

            &-answer {
                font-size: 2rem;
            }
        }
    }
}

@media (min-width: 577px) and (max-width: $breakpoint-sm) {

    .faq {

        &__header {
            justify-content: flex-start;

            &-title {
                font-size: 6rem;
                margin-left: 9rem;
                margin-bottom: 5rem;
                text-align: left;
            }
        }

        &__main {
            margin-top: 3rem;

            &-title {
                font-size: 4rem;
                padding: 0 2rem;
                margin-bottom: 3rem;
            }
        }
    }
}

@media (min-width: 767px) and (max-width: $breakpoint-lg) {

    .faq {

        &__header {

            &-title {
                font-size: 8rem;
                margin-right: 7rem;
            }
        }

        &__main {
            margin-top: 5rem;

            &-title {
                font-size: 5rem;
                padding: 0 3rem;
                margin-bottom: 5rem;
            }
        }
    }
}