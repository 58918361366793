@use '../../styles/global.scss' as *;
@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;


.Card {
    padding: 2rem 4rem 0 4rem;

    @media (min-width: 577px) and (max-width: $breakpoint-md) {
        padding: 2rem .5rem 0 .5rem;
    }

    @media (max-width: $breakpoint-xs) {
        padding: .5rem 0;
    }

    &__container {
        color: gray;
        display: flex;
        align-items: center;
        flex-direction: column;
        -ms-flex-align: center;
    }

    &__avatar {
        border: 5px solid $bgBlue;

        @media (max-width:$breakpoint-sm) {
            border: 2px solid $bgBlue;
        }
    }

    &__name {
        font-size: 2.3rem;
        font-weight: bold;
        color: $textGreyblue;

        @media (min-width: 993px) and (max-width: $breakpoint-xl) {
            font-size: 2.2rem;
        }


        @media (min-width: 577px) and (max-width: $breakpoint-md) {
            font-size: 2rem;
        }

        @media (max-width: $breakpoint-xs) {
            font-size: 1.8rem;
        }
    }

    &__review {
        margin: 3rem 0 0 0;
        font-size: 2.2rem;
        text-align: center;
        color: $textGreyblue;
        line-height: 2.8rem;
        width: 95%;
        display: flex;
        flex-direction: column;

        @media (min-width: 577px) and (max-width: $breakpoint-xl) {
            font-size: 2rem;
        }

        @media (max-width: $breakpoint-xs) {
            font-size: 1.6rem;
            line-height: 2.2rem;
            margin: 1rem 0 1rem 0;
        }
    }

    &__toggle {
        color: white;
        cursor: pointer;
        // display: block;
        width: 15rem;
        align-self: center;
        border: 3px solid $bgBlue;
        text-transform: uppercase;
        background-color: $bgBlue;
        padding: .5rem;
        border-radius: 5px;
        margin: 1rem 0 1.5rem;
        font-weight: bold;
    }
}