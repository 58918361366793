@use '../../styles/global.scss' as *;
@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;

.notfound {
    background-image: url('../../assets/images/mthua.jpg');
    height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    &__title {
        color: white;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        font-size: 35rem;
        text-align: center
    }

    &__subtitle {
        font-family: Voltaire, 'Times New Roman', Times, serif;
        text-align: center;
        font-size: 8rem;
        color: $bgBlue;
        // color: $bigRed;
        text-shadow: 1px 1px 2px rgba(29, 50, 60, 0.8);
    }

    &__btn {
        @include blueBtn;
    }

    &__btns {
        display: flex;
        justify-content: space-around;
    }

}

@media (max-width: $breakpoint-xs) {
    .notfound {
        &__title {
            font-size: 10rem;
        }

        &__subtitle {
            font-size: 4rem;
        }

        &__btn {
            width: 10rem;
            height: 4rem;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        }
    }
}

@media (min-width: 577px) and (max-width: $breakpoint-lg) {
    .notfound {
        &__title {
            font-size: 20rem;
        }

        &__subtitle {
            font-size: 5rem;
        }
    }
}