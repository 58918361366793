@use './typography.scss' as *;
@use './variables.scss' as *;
@use './mixins.scss' as *;

* {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
}

html,
body {
    overflow-x: hidden;
}

h1 {
    font-size: 20rem;
}

body {
    font-family: Roboto, 'Times New Roman', Times, serif;
    font-size: 1.5rem;
}

button {
    text-transform: uppercase;
    font-family: Mitr, 'Times New Roman', Times, serif;
}

input::placeholder {
    color: rgba(41, 71, 87, 0.8);
}

.hidden {
    display: none;
}

a {
    text-decoration: none;
}

/* Slick Slider  */
.slick-prev::before,
.slick-next::before {
    display: none;
}

.slick-slider {
    width: 72vw;
    padding: 25px !important;
    border-radius: 50px !important;
    box-shadow: 1px 3px 21px 9px $bgBlue !important;
    min-width: auto;
    background-color: rgb(221, 234, 240);

    @media (max-width: $breakpoint-xs) {
        width: 70vw;
        padding: 2rem !important;
    }

    @media (min-width: 769px) and (max-width: $breakpoint-md) {
        width: 78vw;
    }

    @media (min-width: 577px) and (max-width: $breakpoint-sm) {
        width: 82vw;
    }



}

.slick-next {
    right: -41px;

    @media (max-width: $breakpoint-xs) {
        right: -36px;
    }
}

/* Customization of Slider Navigations*/

// .ft-slick__dots--custom {
//     height: 8px;
//     width: 14px;
//     background-color: #ad6f69;
//     border-radius: 4px;
//     position: relative;
// }

// .slick-dots li {
//     width: 20px;
//     height: 0px;
//     margin: 10px -10px;
//     transition: width 0.3s ease-in-out;
// }

// .slick-dots .slick-active .ft-slick__dots--custom {
//     width: 20px;
//     top: 0px;
//     overflow: hidden;
//     background: #994d47;
//     margin-top: 26px;
// }