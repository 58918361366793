@use './variables.scss' as *;
@use './typography.scss' as *;

// big title
@mixin redTitle {
    font-size: 10rem;
    color: $bigRed;
    font-family: Voltaire, Yeseva, 'Times New Roman', Times, serif;
    text-shadow: 1px 1px 2px rgba(85, 0, 18, 0.8);
    text-transform: uppercase;
    font-weight: bold;
}

// subtitle
@mixin blueSub {
    font-family: Votaire, Yeseva, 'Times New Roman', Times, serif;
    color: $textGreyblue;
}

// btn style
@mixin blueBtn {
    background-color: $btnBlue;
    height: 5rem;
    width: 15rem;
    font-size: 120%;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    align-self: flex-start;
    margin: 3rem;
    cursor: pointer;

    &:hover {
        background-color: white;
        color: $btnBlue;
    }
}

@mixin headerHeight {

    @media (min-width: 769px) and (max-width: $breakpoint-md) {
        height: 65vh;
        border-bottom-left-radius: 18rem;
    }

    @media (min-width: 577px) and (max-width: $breakpoint-sm) {
        height: 62vh;
        border-bottom-left-radius: 15rem;
    }

    @media (min-width: 380px) and (max-width: $breakpoint-xs) {
        height: 56vh;
        border-bottom-left-radius: 10rem;
    }

    @media (max-width: 379px) {
        height: 52vh;
        border-bottom-left-radius: 8rem;
    }
}