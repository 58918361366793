@use '../../styles/global.scss' as *;
@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;


.privacy {
    background-color: rgba(181, 212, 235, 0.819);

    &__header {
        background-image: linear-gradient(rgba(181, 212, 235, 0.1), rgba(181, 212, 235, 0.25)), url('../../assets/images/cherry.JPG');
        background-position: center;
    }

    &__link {
        text-decoration: none;
    }
}