@use '../../styles/global.scss' as *;
@use '../../styles/variables.scss' as *;
@use '../../styles/mixins.scss' as *;


.terms {

    &__header {
        background-image: linear-gradient(rgba(181, 212, 235, 0.1), rgba(181, 212, 235, 0.25)), url('../../assets/images/dragon_boats.jpg');
    }
}